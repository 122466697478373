<template>
  <div class="container">
    <div class="welcome container">
      <img src="/img/paladi.png" class="welcome__logo" />
      <div class="welcome__title">
        Добро пожаловать!
      </div>
      <div class="welcome__subtitle">Вы успешно зарегистрированы</div>
      <div class="welcome__subtitle">Вам отправлена СМС с паролем для входа на сайт</div>
      <router-link to="/login" class="btn welcome__btn" replace>
        Авторизация
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationCompleted",

}
</script>
